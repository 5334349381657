import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { Spinner } from 'react-svg-spinner';

const SwapScreen = ({ userId }) => {
  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);
  const [itemsCopy, setItemsCopy] = useState([]);
  const [itemsCopy2, setItemsCopy2] = useState([]);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [balance, setBalance] = useState(0);
  const [balance2, setBalance2] = useState(0);

  var _id = JSON.parse(localStorage.getItem("_id"));
  var name = JSON.parse(localStorage.getItem("name"));

  const [itemCounts, setItemCounts] = useState({});
  const [itemsRepeatedTwice, setItemsRepeatedTwice] = useState([]);
  const [cookies,setCookies] = useState('')
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/getUser', { _id: _id });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [_id]);

  const [difference, setDifference] = useState(0);
  const [canExchange, setCanExchange] = useState(false);
  const [fromBalance, setFromBalance] = useState(false)
  useEffect(() => {
    const calculateMissingAmount = () => {
      if (!data || typeof data.balance !== 'number') {
        console.error('Invalid data or data.balance');
        return;
      }

      let neededAmount = balance - balance2;
      console.log('Needed Amount:', neededAmount);

      if (balance < balance2) {
        if (neededAmount > 0) {
          setDifference(neededAmount);  // Użytkownik ma wystarczająco dużo na wymianę
          setCanExchange(true);
          setFromBalance(false)
        } else {
          if(data.balance > neededAmount){
            setDifference(neededAmount);
            setCanExchange(true);  
            setFromBalance(true)
          } else {
            setDifference(neededAmount);
            setCanExchange(false); 
            setFromBalance(false)
          } 
        }
      } else {
        setDifference(neededAmount);  // Użytkownik ma wystarczająco dużo na wymianę
        setCanExchange(true);
        setFromBalance(false)
      }
    };

    calculateMissingAmount();
  }, [balance, balance2, data]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch inventory from swap API
        const swapResponse = await axios.post(`/api/swap`);
        setItems2(swapResponse.data);
        setItemsCopy2(swapResponse.data);
  
        // Liczenie powtarzających się przedmiotów
        const counts = swapResponse.data.reduce((acc, item) => {
          acc[item.name] = (acc[item.name] || 0) + 1;
          return acc;
        }, {});
  
        setItemCounts(counts);
  
        // Znajdowanie przedmiotów, które występują więcej niż dwa razy i dodawanie ich raz do tablicy
        const itemsTwice = Object.keys(counts)
          .filter(name => counts[name] > 2)
          .map(name => swapResponse.data.find(item => item.name === name));
  
        setItemsRepeatedTwice(itemsTwice);
  
        // Jeśli _id jest ustawione, fetch user items
        if (_id !== null) {
          const userItemsResponse = await axios.post('/api/userItems', { _id: _id });
          setItems(userItemsResponse.data);
          setItemsCopy(userItemsResponse.data);
  
          // Aktualizacja kodu bez liczenia powtarzających się przedmiotów
          const itemsTwiceNames = itemsTwice.map(item => item.name);
  
          const updatedItems = userItemsResponse.data.map(item => ({
            ...item,
            isRepeatedTwice: itemsTwiceNames.includes(item.name)
          }));
  
          setItems(updatedItems);
        }
      } catch (error) {
        setError(error.message);
      }
    };
  
    fetchData();
  }, [_id, userId]);
  
  const fetchInventory = async () => {
    try {
      setSelectedItems([]);
      setSelectedItems2([]);
      setBalance(0);
      setBalance2(0);
      const response = await axios.post(`/api/swap`);
      setItems2(response.data);
      setItemsCopy2(response.data);
  
      // Liczenie powtarzających się przedmiotów
      const counts = response.data.reduce((acc, item) => {
        acc[item.name] = (acc[item.name] || 0) + 1;
        return acc;
      }, {});
  
      setItemCounts(counts);
  
      // Znajdowanie przedmiotów, które występują więcej niż dwa razy i dodawanie ich raz do tablicy
      const itemsTwice = Object.keys(counts)
        .filter(name => counts[name] > 2)
        .map(name => response.data.find(item => item.name === name));
  
      setItemsRepeatedTwice(itemsTwice);
  
    } catch (error) {
      setError(error.message);
    }
    const myElement = document.getElementById("icon2");
    myElement.classList.add('disabled');
    setTimeout(() => {
      myElement.current.classList.remove('disabled');
    }, 20000);
  }
  
  const fetchUserInventory = async () => {
    if (_id !== null) {
      try {
        setSelectedItems([]);
        setSelectedItems2([]);
        setBalance(0);
        setBalance2(0);
        const response = await axios.post('/api/userItems', { _id: _id });
        setItems(response.data);
        setItemsCopy(response.data);
  
        // Aktualizacja kodu bez liczenia powtarzających się przedmiotów
        const itemsTwiceNames = itemsRepeatedTwice.map(item => item.name);
  
        const updatedItems = response.data.map(item => ({
          ...item,
          isRepeatedTwice: itemsTwiceNames.includes(item.name)
        }));
  
        setItems(updatedItems);
      } catch (error) {
        setError(error.message);
      }
      const myElement = document.getElementById("icon1");
      myElement.classList.add('disabled');
      setTimeout(() => {
        myElement.current.classList.remove('disabled');
      }, 20000);
    }
  }
  
  const handleItemClick = (item) => {
    const index = selectedItems.findIndex((selectedItem) => selectedItem.userAssetId === item.userAssetId);
    
    // Sprawdź, czy liczba zaznaczonych przedmiotów nie przekracza 4
    if (selectedItems.length >= 4 && index === -1) {
        return; // Zatrzymaj funkcję, jeśli limit został osiągnięty
    }
    
    if (index === -1) {
        // Dodaj przedmiot do wybranych i zaktualizuj saldo
        const updatedItems = [...selectedItems, item];
        updatedItems.sort((a, b) => b.recentAveragePrice - a.recentAveragePrice); // Sortuj od największej do najmniejszej recentAveragePrice
        setSelectedItems(updatedItems);
        setBalance(balance + item.recentAveragePrice);
    } else {
        // Usuń przedmiot z wybranych i zaktualizuj saldo
        const updatedItems = [...selectedItems];
        updatedItems.splice(index, 1);
        setSelectedItems(updatedItems);
        setBalance(balance - item.recentAveragePrice);
    }
};

const handleItemClick2 = (item) => {
    const index = selectedItems2.findIndex((selectedItem) => selectedItem.userAssetId === item.userAssetId);

    // Sprawdź, czy liczba zaznaczonych przedmiotów nie przekracza 4
    if (selectedItems2.length >= 4 && index === -1) {
        return; // Zatrzymaj funkcję, jeśli limit został osiągnięty
    }

    if (index === -1) {
        // Dodaj przedmiot do wybranych i zaktualizuj saldo
        const updatedItems = [...selectedItems2, item];
        updatedItems.sort((a, b) => b.recentAveragePrice - a.recentAveragePrice); // Sortuj od największej do najmniejszej recentAveragePrice
        setSelectedItems2(updatedItems);
        setBalance2(balance2 + item.recentAveragePrice);
    } else {
        // Usuń przedmiot z wybranych i zaktualizuj saldo
        const updatedItems = [...selectedItems2];
        updatedItems.splice(index, 1);
        setSelectedItems2(updatedItems);
        setBalance2(balance2 - item.recentAveragePrice);
    }
};

const [trade, setTrade] = useState([]);


const sendTrade = async () => {
  if (balance + data.balance > balance2) {
    try {
      const response = await axios.post('/api/sendTrade', { _id: _id, items1: selectedItems, items2: selectedItems2, value1: balance, value2: balance2 });
      if (response.data) {
        document.getElementById('trade').style.display = 'block';
        setTrade(response.data.trade);
        console.log(response.data.trade);

        // Start the interval to refresh trade data
        startTradeRefresh(response.data.trade._id);
      }
    } catch (error) {
      console.log(error.message);
    }
  }
};

// Function to start refreshing trade data
const startTradeRefresh = (tradeId) => {
  // Clear any existing intervals
  if (window.tradeRefreshInterval) {
    clearInterval(window.tradeRefreshInterval);
  }

  // Set up a new interval
  window.tradeRefreshInterval = setInterval(async () => {
    try {
      const response = await axios.post(`/api/reloadTrade`, { tradeId: tradeId });
      const data = response.data;  // Use response.data instead of response.json()

      // Update the trade
      setTrade(data.trade);
      console.log(data.trade);

      // Check if the trade status is 'Completed' and clear the interval if so
      if (data.trade.status === 'Completed') {
        clearInterval(window.tradeRefreshInterval);
        window.tradeRefreshInterval = null; // Optional: clear the reference to the interval
        console.log('Trade completed, stopping refresh.');
      }

    } catch (error) {
      console.error('Error fetching trade data:', error);
    }
  }, 10000);
};

  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Price: Max');

  const [showDropdown2, setShowDropdown2] = useState(false);
  const [selectedOption2, setSelectedOption2] = useState('Price: Max');

  const options = ['Price: Max', 'Price: Min'];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowDropdown(false);
    
    let sortedItems = [...items];
    if (option === 'Price: Min') {
      sortedItems.sort((a, b) => a.recentAveragePrice - b.recentAveragePrice);
    } else if (option === 'Price: Max') {
      sortedItems.sort((a, b) => b.recentAveragePrice - a.recentAveragePrice);
    } 
    setItems(sortedItems);
  };

  const handleOptionClick2 = (option) => {
    setSelectedOption2(option);
    setShowDropdown2(false);
    
    let sortedItems = [...items2];
    if (option === 'Price: Min') {
      sortedItems.sort((a, b) => a.recentAveragePrice - b.recentAveragePrice);
    } else if (option === 'Price: Max') {
      sortedItems.sort((a, b) => b.recentAveragePrice - a.recentAveragePrice);
    } 
    setItems2(sortedItems);
  };

  const [nameS, setNameS] = useState('')
  const [nameS2, setNameS2] = useState('')

  const search = (text) => {
    // Zapamiętaj oryginalną listę przed filtracją
    const originalItems = [...itemsCopy]; // Załóżmy, że itemsCopy jest oryginalną listą przed filtrowaniem
  
    setNameS(text);
    const word = text;
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;
  
    if (word) {
      const filtered = originalItems.filter(entry =>
        Object.values(entry).some(val =>
          typeof val === "string" && val.includes(capitalizedWord)
        )
      );
      setItems(filtered);
    } else {
      // Jeśli nie ma żadnej wyszukiwanej frazy, przywróć oryginalną listę
      setItems(originalItems);
    }
  };

  const search2 = (text) => {
    // Zapamiętaj oryginalną listę przed filtracją
    const originalItems = [...itemsCopy2]; // Załóżmy, że itemsCopy2 jest oryginalną listą przed filtrowaniem
  
    setNameS2(text);
    const word = text;
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;
  
    if (word) {
      const filtered = originalItems.filter(entry =>
        Object.values(entry).some(val =>
          typeof val === "string" && val.includes(capitalizedWord)
        )
      );
      setItems2(filtered);
    } else {
      // Jeśli nie ma żadnej wyszukiwanej frazy, przywróć oryginalną listę
      setItems2(originalItems);
    }
  };

  function hidePopup() {
    document.getElementById('trade').style.display = 'none';
  }

  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);

  const handleSwitchChange = () => {
    setShowOnlyAvailable(prevState => !prevState);
  };

  const filteredItems = showOnlyAvailable 
    ? items.filter(item => !item.isOnHold)
    : items;

    const [showOnlyAvailable2, setShowOnlyAvailable2] = useState(true);

    const handleSwitchChange2 = () => {
      setShowOnlyAvailable2(prevState => !prevState);
    };
  
    const filteredItems2 = showOnlyAvailable2 
      ? items2.filter(item => !item.isOnHold)
      : items2;

      


  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
    <div className="popup2" id="trade">
      <div className="blocker2" onClick={hidePopup}>
      <div className='contentTrade' onClick={(e) => e.stopPropagation()}>
          <div className='orderTop'>
            <a>Order created!</a>
          </div>
          <div className='orderMid'>
          <a>Now, please send a trade offer to our BOT with the exact items listed below.</a>
          <a>After sending the trade offer, wait for the BOT to accept it!</a>
          </div>

          <div className='marketOrderMain2'>
            <a>You Sending</a>
            <a>You Receiving</a>
          </div>

          <div className='marketOrderMain'>
          <div className='marketOrder'>
          {trade.asset1 && trade.asset1.length > 0 && trade.asset1.map((item) => (
            <div className='marketOrderItem'>
              <div className='imageWrapper'>
                <img className='marketImg3' src={`https://adurite-images.onrender.com/images?assetId=${item}&width=420&height=420&format=png`}></img>
              </div>
            </div>
          ))} 

          </div>
          <div className='marketOrder'>
          {trade.asset2 && trade.asset2.length > 0 && trade.asset2.map((item) => (
            <div className='marketOrderItem'>
              <div className='imageWrapper'>
                <img className='marketImg3' src={`https://adurite-images.onrender.com/images?assetId=${item}&width=420&height=420&format=png`}></img>
              </div>
            </div>
          ))} 
          </div>
          </div>

          <a href="https://www.roblox.com/users/5228870992/trade" target="_blank"><button className='submit-button3'>Send Trade</button></a>


          <div className="loading-container">
    <div className="loading-item">
      <div className="loading-text">Order Created</div>
      <i class="fa-duotone fa-solid fa-check tick-icon"></i>
    </div>
    <div className="loading-separator"></div>
    <div className="loading-item">
      <div className="loading-text">Waiting for Trade</div>
      {trade.status === 'Sent' || trade.status === 'Completed' ? (
        <i class="fa-duotone fa-solid fa-check tick-icon"></i>
      ):(
        <i class="fa-solid fa-arrows-rotate rotating-icon"></i>
      )}
    </div>
    <div className="loading-separator"></div>
    <div className="loading-item">
      <div className="loading-text">Trade Completed</div>
      {trade.status === 'Completed' ? (
        <i class="fa-duotone fa-solid fa-check tick-icon"></i>
      ):(
        <i class="fa-solid fa-arrows-rotate rotating-icon"></i>
      )}
    </div>
  </div>


        </div>
      </div>
    </div>

    <div className='mainDiv'>
      <div className='marketDiv'>
        <div className='swapTop'>
          <div className='marketTab'>
            <a className="offer-link">You Offer</a>
            <a className="offer-link2" style={{ color: '#d1bf84' }}><svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path></svg> {Number(balance).toLocaleString() || 'N/A'}</a>
          </div>
        {selectedItems.length == 0 ? (
          <>
          <div className='marketEmpty'>
            <a>Your Offer</a>
            <a className='addItems'>Add the items you want to trade</a>
          </div>
          </>
        ):(
          <>
        <div className='marketTop'>
        {selectedItems.map((item) =>(
            <div 
              key={item.userAssetId}
              className={`marketItem`}
              id={`${item.assetId}`}
              onClick={() => handleItemClick(item)}
            >
            <div className='imageWrapper'>
              <img className='marketImgBlur2' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
              <img className='marketImg2' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
            </div>
              <a className='marketName'>{item.name.length > 10 ? `${item.name.slice(0, 10)} ...` : item.name}</a>
             <a className='marketRap'><svg width="11" height="13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path></svg> {Number(item.recentAveragePrice).toLocaleString() || 'N/A'}</a>
           </div>
         ))}
        </div>
          </>
        )}  
        </div>


    <div className='swapTop'>
          <div className='marketTab'>
            
            <a className="offer-link">Selected: {selectedItems.length}/4</a>
            <div className="selector-wrapper" onClick={() => setShowDropdown(!showDropdown)}>
      <span className="icon">≡</span> {/* Adjust this to match your icon */}
      {selectedOption} <span className="arrow">▾</span>
      {showDropdown && (
        <div className="dropdown">
          {options.map((option, index) => (
            <div className="dropdown-item" key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
    

      <span className='sliderText'>Only Tradable</span>
      <label className="switch">
        <input 
          type="checkbox" 
          checked={showOnlyAvailable} 
          onChange={handleSwitchChange} 
        />
        <span className="slider"></span>
      </label>

    <Form.Control className='searchBox'
    type='name'
    placeholder='Search ...'
    value={nameS}
    onChange={(e) => search(e.target.value)}
  ></Form.Control>


<a id='icon1' onClick={fetchUserInventory}><i class="fa-solid fa-arrows-rotate refreshIcon"></i></a>
          </div>

          {!_id ? (
          <>
          <div className='marketEmpty2'>
            <a>Login first</a>
            <a className='addItems'>To see all your limiteds</a>
          </div>
          </>
        ):(

        <div className='marketMain'>
        {filteredItems.map((item) => (
          <div
            key={item.userAssetId}
            className={`marketItem ${item.isRepeatedTwice ? 'repeated-twice' : ''} ${(item.recentAveragePrice === 0 || item.recentAveragePrice === null || item.recentAveragePrice > 100000 || item.isOnHold) ? 'disabled' : ''} ${selectedItems.some((selectedItem) => selectedItem.userAssetId === item.userAssetId) ? 'selected' : ''}`}
            id={`${item.assetId}`}
            onClick={() => handleItemClick(item)} // Handle click on item
          >
            <div className='imageWrapper'>
            {item.isOnHold && (
        <div className="lockOverlay">
        <svg aria-hidden="true" focusable="false" data-prefix="mdi" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-inline--fa fa-lock lockIcon">
          <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
        <span className="lockText">Trade Hold</span>
      </div>
        
      )}
      {item.isRepeatedTwice && (
        <div className="lockOverlay">
        <svg aria-hidden="true" focusable="false" data-prefix="mdi" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-inline--fa fa-lock lockIcon">
          <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
        <span className="lockText">Bot Overloaded</span>
      </div>
        
      )}
              <img className='marketImgBlur' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
              <img className='marketImg' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
            </div>
            <a className='marketName'>{item.name.length > 10 ? `${item.name.slice(0, 10)} ...` : item.name}</a>
            <a className='marketRap'><svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path></svg> {Number(item.recentAveragePrice).toLocaleString() || 'N/A'}</a>
          </div>
        ))}
      </div>

        )}

      </div>
         </div>


         <div className='menu'>
        {!data ? (
         <button onClick={() => sendTrade()}
         className='submit-button'
         disabled={balance2 > balance || balance2 === 0}
       >
         Trade
       </button>
        ):(
          <button onClick={() => sendTrade()}
          className='submit-button'
          disabled={balance2 > balance+data.balance || balance2 === 0}
        >
          Trade
        </button>
        )}

      <div className='balanceTrade'>
        {difference >= 0 ? (
          <>
                  <a className='balanceColor'><svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path>
            </svg> {difference}</a>
            <a className='balanceText'>Added to balance</a>
          </>
        ):(
          <>
          {fromBalance ? (
          <>
          <a className='balanceColor'><svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path>
    </svg> {difference}</a>
    <a className='balanceText'>Taken from balance</a>
  </>
          ):(
            <>
            <a className='balanceColor'><svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path>
      </svg> {difference}</a>
      <a className='balanceText'>Needed for trade</a>
    </>
          )}
          </>
        )}

      </div>
      
         </div>

         <div className='marketDiv'>
        <div className='swapTop'>
          <div className='marketTab'>
          <a className="offer-link" style={{ color: '#d1bf84' }}><svg width="13" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path></svg> {Number(balance2).toLocaleString() || 'N/A'}</a>
            <a className="offer-link2">You Receive</a>
          </div>
          {selectedItems2.length == 0 ? (
          <>
          <div className='marketEmpty'>
            <a>You Receive</a>
            <a className='addItems'>Add the items you want to receive from our inventory</a>
          </div>
          </>
        ):(
          <>  
        <div className='marketTop'>
        {selectedItems2.map((item) =>(
            <div
              key={item.userAssetId}
              className={`marketItem`}
              id={`${item.assetId}`}
              onClick={() => handleItemClick2(item)}
            >
            <div className='imageWrapper'>
              <img className='marketImgBlur2' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
              <img className='marketImg2' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
            </div>
              <a className='marketName'>{item.name.length > 10 ? `${item.name.slice(0, 10)} ...` : item.name}</a>
             <a className='marketRap'><svg width="11" height="13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path></svg> {Number(item.recentAveragePrice).toLocaleString() || 'N/A'}</a>
           </div>
         ))}
        </div>
        </>
        )}
        </div>


    <div className='swapTop'>
          <div className='marketTab'>
          <a className="offer-link">Selected: {selectedItems2.length}/4</a>
          <div className="selector-wrapper" onClick={() => setShowDropdown2(!showDropdown2)}>
      <span className="icon">≡</span> {/* Adjust this to match your icon */}
      {selectedOption2} <span className="arrow">▾</span>
      {showDropdown2 && (
        <div className="dropdown">
          {options.map((option, index) => (
            <div className="dropdown-item" key={index} onClick={() => handleOptionClick2(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>

    <span className='sliderText'>Only Tradable</span>
      <label className="switch">
        <input 
          type="checkbox" 
          checked={showOnlyAvailable2} 
          onChange={handleSwitchChange2} 
        />
        <span className="slider"></span>
      </label>

    <Form.Control className='searchBox'
    type='name'
    placeholder='Search ...'
    value={nameS2}
    onChange={(e) => search2(e.target.value)}
  ></Form.Control>

            <a id='icon2' onClick={fetchInventory}><i class="fa-solid fa-arrows-rotate refreshIcon"></i></a>
          </div> 
        <div className='marketMain'>
        {filteredItems2.map((item) => (
    <div
        key={item.userAssetId}
        className={`marketItem ${(item.recentAveragePrice === 0 || item.recentAveragePrice === null || item.recentAveragePrice > 100000 || item.isOnHold) ? 'disabled' : ''} ${selectedItems.some((selectedItem) => selectedItem.userAssetId === item.userAssetId) ? 'disabled' : ''} ${selectedItems2.some((selectedItem) => selectedItem.userAssetId === item.userAssetId) ? 'selected' : ''}`}
        id={`${item.assetId}`}
        onClick={() => handleItemClick2(item)}    >

        <div className='imageWrapper'>
        {item.isOnHold && (
        <div className="lockOverlay">
        <svg aria-hidden="true" focusable="false" data-prefix="mdi" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="svg-inline--fa fa-lock lockIcon">
          <path fill="currentColor" d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />
        </svg>
        <span className="lockText">Trade Hold</span>
      </div>
        
      )}
            <img className='marketImgBlur' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
            <img className='marketImg' src={`https://adurite-images.onrender.com/images?assetId=${item.assetId}&width=420&height=420&format=png`} alt={item.name}></img>
        </div>
        <a className='marketName'>{item.name.length > 10 ? `${item.name.slice(0, 10)} ...` : item.name}</a>
        <a className='marketRap'>
            <svg width="11" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path>
            </svg> {Number(item.recentAveragePrice).toLocaleString() || 'N/A'}
        </a>
    </div>
))}
      </div>

      </div>
         </div>

       </div>

       </>
  );
};

export default SwapScreen;