import React, { useRef,useState,useEffect } from "react";
import './style.css'

const PrivScreen = () => {
 return (
   <>
    <div className='tosDiv'>
    <h1>Privacy Policy</h1>

<h2>Cookies</h2>
<p>Cookies are files with a small amount of data that is commonly used as an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.</p>
<p>Our website uses these "cookies" to collect information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
<p>For more general information on cookies, please read <a href="https://www.cookies.com/">"What Are Cookies"</a>.</p>


<h2>Security</h2>
<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

<h2>Children's Privacy</h2>
<p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>

<h2>Changes to This Privacy Policy</h2>
<p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

<h2>Contact Us</h2>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>

    </div>
   </>
 )
}

export default PrivScreen;