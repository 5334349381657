import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import Header from './screens/Header';
import Swap from './screens/Swap';
import Swap2 from './screens/Swap2';
import Bottom from './screens/Bottom';
import Tos from './screens/tos';
import { useMediaQuery } from 'react-responsive';
import Policy from './screens/priv'
import Login from "./screens/Login";
import Callback from "./screens/Callback";
const App = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Routes>
          <Route
            path='/'
            exact
            element={isDesktop ? <Swap /> : <Swap2 />}
          />
          <Route path='/tos' exact element={<Tos />} />
          <Route path='/policy' exact element={<Policy />} />
          <Route path="/login" element={<Login />} />
          <Route path="/callback" element={<Callback />} />
        </Routes>
      </main>
      <Bottom />
    </Router>
  );
};

export default App;