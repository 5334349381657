import React, { useEffect } from "react";
import axios from "axios";

const Callback = () => {
  useEffect(() => {
    // When the user is redirected back to the app, fetch user info
    const fetchUserData = async () => {
      try {
        const response = await axios.get("http://localhost:3000/home", {
          withCredentials: true,
        });
        // Handle the user's data, such as saving it in the state or context
        console.log(response.data);
      } catch (err) {
        console.error("Error fetching user data", err);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div>
      <h2>Welcome back!</h2>
      <p>Loading your data...</p>
    </div>
  );
};

export default Callback;