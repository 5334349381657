import React, { useState,useEffect } from 'react'
import './header.css'
import axios from 'axios'
import { Form, Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { validateLocaleAndSetLanguage } from 'typescript';

const Header = () => {
  var _id = JSON.parse(localStorage.getItem("_id"));
  var name = JSON.parse(localStorage.getItem("name"));
  var id = JSON.parse(localStorage.getItem("id"));

  const navigate = useNavigate();

  const [words, setWords] = useState('')
  const [data, setData] = useState(null);
  const [username, setUsername] = useState('')
  const [message, setMessage] = useState('');
  const [link,setLink] = useState('')

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state'); // Przechwytujemy state z adresu URL
  
    if (code && state) { // Upewniamy się, że oba są dostępne
      const sendCode = async () => {
        try {
          const response = await axios.post('/api/authenticate', { code, state }); // Wysyłamy code i state do backendu
          if(response){
            console.log('Success:', response.data);
            localStorage.setItem('_id', JSON.stringify(response.data._id));
            localStorage.setItem('name', JSON.stringify(response.data.name));
            localStorage.setItem('id', JSON.stringify(response.data.id));
            window.location.href = "https://bloxyswap.com/";
          }
          
        } catch (error) {
          console.error('Error authenticating:', error);
          setMessage('Authentication failed.');
        }
      };
  
      sendCode();
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post('/api/getUser', { _id: _id });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [_id]);

  function showPopup() {
    window.location.href = "https://bloxyswap.com/login";
  }

  const logout = () => {
    localStorage.removeItem("id")
    localStorage.removeItem("_id")
    localStorage.removeItem("name")
    window.location.href = "https://bloxyswap.com/";
  }

  function showPopup2() {
    document.getElementById('deposit').style.display = 'block';
  }

  function hidePopup2() {
    document.getElementById('deposit').style.display = 'none';
  }

  function showPopup4() {
    document.getElementById('freeRobux').style.display = 'block';
  }

  function hidePopup4() {
    document.getElementById('freeRobux').style.display = 'none';
  }

  const [amount, setAmount] = useState(5);

  const handlePayment = async () => {
    try {
      const response = await axios.post('/create-charge', { amount, _id });
      const charge = response.data;
      window.location.href = charge.hosted_url;
    } catch (error) {
      console.error('Error creating charge:', error);
    }
  };

  const handleAmountChange = (newAmount) => {
    setAmount(newAmount);
  };

  const claimBonus = async () => {
    const response = await axios.post('/api/freeRobux', { _id });
    setData(response.data);
    document.getElementById('freeRobux').style.display = 'none';
  }

  const goHome = () => {
    navigate('/')
  }

  if (loading) {
    return <div className='header'></div>;
  }

  return (
    <>
<div className="popup4" id="freeRobux">
      <div className="blocker4" onClick={() => hidePopup4()}>
        <div className='contentTrade2' onClick={(e) => e.stopPropagation()}>
            <a className='bonusTitle'>Claim your <a className='greenColor'>Free 50 Robux</a> bonus!</a>
            <a className='bonusSmall'>Join our discord and get your free robux.</a>
            <a href="https://discord.gg/gnnpQwWJMA" target="_blank"><Button className='bonusBtn' onClick={() => claimBonus()}>Claim Bonus</Button></a>
        </div>
      </div>
    </div>


<div className="popup3" id="deposit">
      <div className="blocker3" onClick={() => hidePopup2()}>
        <div className='contentTrade2' onClick={(e) => e.stopPropagation()}>


        <a className='bigPayment'>Add Balance</a>
      <div className="amount-section">
        <a className='paymentText'>Payment Amount</a>
        <input className='balanceForm'
          type="number" 
          value={amount} 
          onChange={(e) => handleAmountChange(Number(e.target.value))} 
          min="1" 
        />
        <div className="amount-buttons">
          {[5, 10, 25, 50, 100, 200, 500, 1000].map((value) => (
            <button 
              key={value} 
              className={amount === value ? 'selected' : ''} 
              onClick={() => handleAmountChange(value)}
            >
              ${value}.00
            </button>
          ))}
        </div>
      </div>
      <div className="bonus-section">
        <a className='paymentText2'>Receiving Amount</a>
        <span><svg width="22" height="24" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.866 1.024a2.58 2.58 0 0 1 2.58 0L8.827 2.4a2.58 2.58 0 0 1 1.29 2.233v2.752a2.58 2.58 0 0 1-1.29 2.234l-2.383 1.376c-.798.46-1.781.46-2.58 0L1.484 9.619a2.58 2.58 0 0 1-1.29-2.234V4.633A2.58 2.58 0 0 1 1.483 2.4l2.383-1.376ZM8.13 3.866 5.524 2.361a.737.737 0 0 0-.737 0L2.18 3.866a.737.737 0 0 0-.368.638v3.01c0 .264.14.507.368.639l2.607 1.505a.737.737 0 0 0 .737 0L8.13 8.153a.737.737 0 0 0 .368-.639v-3.01a.737.737 0 0 0-.368-.638ZM5.892 1.723a1.474 1.474 0 0 0-1.473 0L1.812 3.228c-.456.263-.737.75-.737 1.276v3.01c0 .527.28 1.013.737 1.277l2.607 1.505a1.474 1.474 0 0 0 1.473 0L8.5 8.79c.456-.264.737-.75.737-1.277v-3.01c0-.526-.28-1.013-.737-1.276L5.892 1.723Zm.555 2.995H3.864V7.3h2.583V4.718Z" fill="#d1bf84" fill-opacity="0.8"></path>
</svg>
             <a className='goldPrice'> {(amount * 100).toFixed(0)}</a></span>
      </div>
      <button className="payment-button" onClick={()=> handlePayment()}>Complete Purchase</button>

        </div>
      </div>
    </div>

 <div className="header">
 <div className='logoHeader'>
          <img src="/img/logo2.png" class="logoIcon" onClick={() => goHome()}></img>
          <a className='logoText' onClick={() => goHome()}>BloxySwap</a>
          <a href='https://discord.gg/gnnpQwWJMA' target="_blank" className='headerBtn'><i class="fa-brands fa-discord"></i></a>
          {_id && data && data.free && (
          <a className='logoText freeRobux' onClick={() => showPopup4()}>Claim Free Robux</a>
          )}
          {!data && (
          <a className='logoText freeRobux' onClick={() => showPopup()}>Claim Free Robux</a>
          )}
        </div>
      <div className="header-content">

        <div className="header-user-info">
          {_id && data ? (
            <>
        <div class="profile">
        <div className="money-display">
      <div className="money-icon">
      <img src="/img/robux.png" class="coin-icon"></img>

      </div>
      <span className="money-amount">{data.balance}</span>
      <button className="add-button" onClick={() => showPopup2()}>+</button>
    </div>

        </div>
        <div class="buttons">
            <img className='profile-img' loading="lazy" width="30" height="30" decoding="async" data-nimg="1" src={`https://api.bloxempire.com/user/avatar2?userId=${id}`}></img>
            <button class="button logout" onClick={() => logout()}><i class="fa-solid fa-arrow-right-from-bracket logoutIcon"></i></button>
        </div>
            </>
          ):(
            <>
            <Button className='headerLogin' onClick={() => showPopup()}>Login</Button>
            </>
          )}

        </div>
      </div>
    </div>

    </>
    
  )
}

export default Header