import React, { useState } from "react";
import axios from "axios";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async () => {
    setLoading(true);
    setError("");
  
    try {
      // Wysłanie żądania do backendu w celu rozpoczęcia logowania
      const response = await axios.get("/login", {
        withCredentials: true, // Uwzględnienie ciasteczek dla sesji
      });
  
      const redirectUrl = response.data.redirectUrl;
      
      if (!redirectUrl) {
        throw new Error("Redirect URL is undefined.");
      }
  
      // Przekierowanie na stronę logowania Roblox
      window.location.href = redirectUrl;
    } catch (err) {
      setError("Login failed. Please try again.");
      console.error(err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Login with Roblox</h2>
      <button onClick={handleLogin} disabled={loading}>
        {loading ? "Logging in..." : "Login with Roblox"}
      </button>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Login;