import React, { useRef,useState,useEffect } from "react";
import './style.css'

const TosScreen = () => {
 return (
   <>
    <div className='tosDiv'>
    <h1>Terms of Service</h1>


<h2>1. Acceptance of Terms</h2>
<p><a>By using Bloxyswap, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any of these terms, you are prohibited from using or accessing this site.</a></p>

<h2>2. Description of Service</h2>
<p><a>Bloxyswap is an online platform that allows users to exchange limited items from Roblox. Bloxyswap is not affiliated, associated, authorized, endorsed by, or in any way officially connected with Roblox Corporation, or any of its subsidiaries or its affiliates.</a></p>

<h2>3. User Accounts</h2>
<p><a>To use our services, you must log in by verifying your Roblox account. You agree to provide accurate and complete information during the verification process and to keep your account information up-to-date.</a></p>
<p><a>Bloxyswap does not collect any data from your Roblox account except for basic account information necessary for the verification process.</a></p>

<h2>4. User Conduct</h2>
<p><a>You agree not to use Bloxyswap for any unlawful or prohibited purpose.</a></p>
<p><a>You agree not to engage in any activity that interferes with or disrupts the services provided by Bloxyswap.</a></p>

<h2>5. Exchange of Items</h2>
<p><a>Users can trade their limited Roblox items for exchange with website items</a></p>
<p><a>Bloxyswap does not guarantee the quality, safety, or legality of the items being exchanged.</a></p>
<p><a>Bloxyswap offers fair trades that missing value of the trade will be added to user website balance.</a></p>

<h2>6. Fees</h2>
<p><a>Bloxyswap may charge fees for certain services. Any applicable fees will be disclosed to you before you use the service.</a></p>

<h2>7. Responsibility for Errors</h2>
  <p><a>Bloxyswap takes responsibility for all errors in the service. Any erroneous transactions will be promptly refunded.</a></p>

<h2>8. Exploitation of Errors</h2>
<p><a>Users found exploiting errors on the site for unjust gain may be banned from using the service.</a></p>

<h2>9. Indemnification</h2>
<p><a>You agree to indemnify, defend, and hold harmless Bloxyswap from and against any and all claims, damages, losses, liabilities, and expenses arising out of your use of the site or services.</a></p>

<h2>10. Changes to Terms</h2>
<p><a>Bloxyswap reserves the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on the site. Your continued use of the site following the posting of changes constitutes your acceptance of such changes.</a></p>

<h2>11. Contact Us</h2>
<p><a>If you have any questions about these Terms, please contact us at bloxyswap@gmail.com.</a></p>
    </div>
   </>
 )
}

export default TosScreen;