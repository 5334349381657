import React, { useRef,useState,useEffect } from "react";
import './bottom.css'
import { useNavigate } from 'react-router-dom';

const Bottom = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/tos')
  }
 return (
   <>
    <div className='bottomDiv'>
      <a className='tos' onClick={() => goHome()}>Terms of Service</a>
      <a>BloxySwap © 2024 - Not affiliated in any way with the Roblox Corporation or any of its trademarks.</a>
    </div>
   </>
 )
}

export default Bottom;